'use strict';

$(document).ready(function () {
// 76175
    $('.product-search-result__list').slick({
        dots: false,
        arrows: true,
        slidesToShow: 3,
        infinite: true,
        responsive: [
            {
                breakpoint: 780,
                settings: {
                    arrows: true,
                    slidesToShow: 2,
                    infinite: true
                }
            }
        ]
    });
    $(document).on('afterChange', '.product-search-result__list', function (slick) {
        // 165827
        // 69359
        $('.slick-active').each(function () {
            var curimg = $(this).find('img.lozad');
            if (!$(curimg).hasClass('loaded')) {
                var cdatscr = $(curimg).attr('data-src');
                $(curimg).attr("src", cdatscr);
                $(curimg).parent().removeClass('skeleton-box');
            }
        });
        setTimeout(() => {
            $(this).find('.slick-current').find('a').eq(0)
                .focus();
        }, 300);
    });
});

$(window).on('load', function () {
    $('.slick-arrow').each(function () {
        $(this).attr('title', $(this).attr('aria-label'));
    });

    // $('.product-tiles__list .slick-active .prod-description, .product-tiles__list .slick-active .tile-date, .product-tiles__list .slick-active .price .value').attr('tabindex', '0');
    // $('.c-review-card__slides .slick-active .c-review-card__quote, .c-review-card__slides .slick-active .c-review-card__name, .c-review-card__slides .slick-active .c-review-card__subject').attr('tabindex', '0');
});
